<template>
  <div>
    <title-fidyah />
    <kalkulator-fidyah />
    <detail-fidyah />
  </div>
</template>

<script>
import TitleFidyah from "./TitleFidyah.vue";
import DetailFidyah from "./DetailFidyah.vue";
import KalkulatorFidyah from "./KalkulatorFidyah.vue";

export default {
  components: {
    TitleFidyah,
    DetailFidyah,
    KalkulatorFidyah,
  },
  metaInfo() {
    return {
      title: "Fidyah",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: "Fidyah",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "  Fidyah adalah sejumlah harta benda dalam kadar tertentu yang wajib diberikan kepada fakir miskin sebagai ganti suatu ibadah yang telah ditinggalkan.  ",
        },
      ],
    };
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
./DetailFidyah.vue./KalkulatorFidyah.vue./TitleFidyah.vue
