<template>
  <!--====== PART START ======-->

  <header class="appie-header-area appie-header-3-area appie-sticky">
    <div class="container">
      <div class="d-lg-none d-block bar-bottom">
        <div class="navbar1">
          <div class="nav-item1">
            <router-link to="/"><i class="fas fa-home"></i> Beranda</router-link>
          </div>
          <div class="nav-item1 active1">
            <router-link to="/campaign"
              ><i class="fas fa-hand-holding-heart"></i> Donasi</router-link
            >
          </div>
          <div class="nav-item1">
            <router-link to="/berita"
              ><i class="fas fa-newspaper"></i> Berita</router-link
            >
          </div>
        </div>
      </div>

      <div class="header-nav-box header-nav-box-3">
        <div class="row align-items-center">
          <div class="col-lg-2 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
            <div class="">
              <router-link to="/">
                <img src="@/assets/images/logo/default.png" alt="logo" class="logo2" />
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2">
            <div class="appie-header-main-menu">
              <nav-items :menuItems="menuItems" :nasted="nasted" />
            </div>
          </div>
          <div class="col-lg-4 col-md-7 col-sm-6 col-6 order-2 order-sm-3">
            <div class="appie-btn-box" style="text-align: right">
              <aside class="widget widget-search d-none d-lg-block">
                <a
                  href="/campaign"
                  class="btn ml-30 text-white"
                  style="background: rgb(208, 163, 42) !important"
                >
                  <i class="fas fa-hand-holding-heart me-2 text-white"></i>Donasi
                </a>
              </aside>

              <div class="toggle-btn ml-30 canvas_open d-lg-none d-block">
                <i class="fa fa-bars" @click="showSidebar"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!--====== PART ENDS ======-->
</template>

<script>
import NavItems from "../NavItem/NavItems.vue";
export default {
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
    nasted: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NavItems,
  },
  data: () => ({ value: 1 }),

  mounted() {
    document.addEventListener("scroll", this.stickMenu);
  },
  methods: {
    showSidebar(e) {
      this.$emit("toggleSidebar", e);
    },
    stickMenu() {
      const result = document.querySelector(".appie-sticky");
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        result.classList.add("sticky");
      } else {
        result.classList.remove("sticky");
      }
    },
  },
};
</script>

<style>
.navbar1 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-around;
  padding: 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.nav-item1 {
  text-align: center;
  flex: 1;
  padding: 10px 0;
}
.nav-item1 a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav-item1 i {
  font-size: 18px;
  margin-bottom: 3px;
}
.active1 {
  background-color: #c4961b;
  color: black;
  font-weight: bold;
}
.active1 a {
  color: black;
}

.logo2 {
  height: 50px;
}

@media (max-width: 600px) {
  .logo2 {
    height: 30px;
  }
}

.fbar {
  font-size: 0.6rem;
}
.v-btn {
  height: 56px !important;
}
</style>
