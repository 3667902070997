<template>
  <section class="contact-section" id="HitungZakat">
    <div class="container">
      <div class="shop-details-box">
        <b-tabs content-class="mt-5" fill>
          <b-tab title="Kalkulator" active>
            <div class="row">
              <div class="col-md-8">
                <div class="contact-form">
                  <h4>Kalkulator Zakat Maal</h4>
                  <p></p>
                  <div class="row">
                    <div class="col-md-6">
                      <span
                        >Harta dalam bentuk tabungan/giro/deposito<span
                          class="text-danger"
                          >*</span
                        ></span
                      >
                      <input type="text" v-model="tabungan" />
                    </div>

                    <div class="col-md-6">
                      <span>Harta dalam bentuk logam mulia</span>
                      <input type="text" v-model="logam" />
                    </div>

                    <div class="col-md-6">
                      <span>Harta dalam bentuk surat berharga </span>
                      <input type="text" v-model="surat" />
                    </div>

                    <div class="col-md-6">
                      <span>Harta dalam bentuk properti</span>
                      <input type="text" v-model="properti" />
                    </div>
                    <div class="col-md-6">
                      <span>Harta dalam bentuk kendaraan</span>
                      <input type="text" v-model="kendaraan" />
                    </div>

                    <div class="col-md-6">
                      <span>Harta dalam bentuk koleksi seni & barang antik </span>
                      <input type="text" v-model="koleksi" />
                    </div>

                    <div class="col-md-6">
                      <span>Harta dalam bentuk stok barang dagangan </span>
                      <input type="text" v-model="stokdagang" />
                    </div>

                    <div class="col-md-6">
                      <span>Harta dalam bentuk lainnya</span>
                      <input type="text" v-model="hartalain" />
                    </div>
                    <div class="col-md-6">
                      <span>Harta dalam bentuk piutang lancar</span>
                      <input type="text" v-model="piutanglancar" />
                    </div>

                    <div class="col-md-6">
                      <span>Masukan harga emas saat ini (dalam gram)*</span>
                      <input type="text" v-model="hargaemas" required />
                    </div>

                    <div class="col-md-6">
                      <span>Hutang jatuh tempo saat membayar kewajiban zakat</span>
                      <input type="text" v-model="hutangtempo" />
                    </div>

                    <hr />
                    <div class="col-md-6">
                      <span>Jumlah Harta</span>
                      <input type="text" :value="totalharta" disabled v-money="money" />
                    </div>
                    <div class="col-md-6">
                      <span>Jumlah harta yang dihitung zakatnya</span>
                      <input type="text" :value="hartazakat" disabled v-money="money" />
                    </div>

                    <div class="col-md-6">
                      <span>Besar nisab zakat maal per tahun</span>
                      <input type="text" :value="nisabAmount" disabled v-money="money" />
                    </div>

                    <div class="col-md-6">
                      <span>Apakah saya wajib berzakat?</span>
                      <input
                        type="text"
                        :value="isZakatRequired ? 'Ya' : 'Tidak'"
                        disabled
                      />
                    </div>

                    <div class="col-md-6">
                      <span>Jumlah zakat yang harus dibayarkan</span>
                      <input
                        type="text"
                        :value="formatNumber(zakatAmount)"
                        disabled
                        v-money="money"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button type="button" class="btn btn-dqm" @click="resetForm">
                        <span class="text-white">Hitung Ulang</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="single-info">
                  <div class="service-details-sidebar">
                    <div>
                      <img src="@/assets/images/niat/maal.png" alt="" />
                    </div>
                    <div class="service-download-widget">
                      <a href="#"
                        ><i class="fal fa-download"></i
                        ><span>Total Zakat Perlu Dibayar/bulan</span></a
                      >
                    </div>
                    <div class="service-download-widget">
                      <input
                        type="text"
                        :value="formatNumber(zakatAmount)"
                        class="zakat"
                        disabled
                        v-money="money"
                      />
                    </div>
                    <div
                      class="service-download-widget mt-3 bg-white"
                      v-if="isZakatRequired"
                    >
                      <div class="donasi-form pt-3 ps-3 pe-3 pb-3">
                        <h5>Profil Donatur</h5>
                        <div class="row">
                          <div class="col-lg-12">
                            <input
                              :id="'nama_' + productid"
                              :name="'nama_' + productid"
                              placeholder="Masukan Nama Donatur"
                              type="text"
                              class="form-control"
                              v-model="namaDonatur"
                              required
                            />
                            <input
                              :id="'nowa_' + productid"
                              :name="'nowa_' + productid"
                              placeholder="Masukan Nomor WhatsApp Aktif"
                              type="text"
                              class="form-control"
                              v-model="nomorWhatsApp"
                              required
                            />
                          </div>
                          <div class="text-center">
                            <button
                              type="button"
                              class="btn btn-dqm w-100 text-white fw-6"
                              @click="donate(productid)"
                              :disabled="!namaDonatur || !nomorWhatsApp"
                            >
                              <span v-if="!isSubmitting">BAYAR ZAKAT</span>
                              <span v-else>
                                <b-spinner
                                  small
                                  variant="white"
                                  label="Spinning"
                                ></b-spinner>
                                Mohon Tunggu...
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Bayar">
            <div class="single-info">
              <div class="service-details-sidebar">
                <div class="service-download-widget mt-3 bg-white">
                  <div class="donasi-form pt-3 ps-3 pe-3 pb-3">
                    <h5>Masukan Zakat Maal</h5>

                    <input
                      type="text"
                      v-model="zakatLangsung"
                      class="form-control"
                      required
                      v-money="money"
                    />
                    <h5>Profil Donatur</h5>
                    <div class="row">
                      <div class="col-lg-12">
                        <input
                          :id="'nama_' + productid"
                          :name="'nama_' + productid"
                          placeholder="Masukan Nama Donatur"
                          type="text"
                          class="form-control"
                          v-model="namaDonatur"
                          required
                        />
                        <input
                          :id="'nowa_' + productid"
                          :name="'nowa_' + productid"
                          placeholder="Masukan Nomor WhatsApp Aktif"
                          type="text"
                          class="form-control"
                          v-model="nomorWhatsApp"
                          required
                        />
                      </div>
                      <div class="text-center d-flex">
                        <button
                          type="button"
                          class="btn btn-danger me-2 text-white fw-6"
                          @click="resetForm2"
                        >
                          <span>Reset</span>
                        </button>

                        <button
                          type="button"
                          :class="{
                            'btn btn-dqm w-100 text-white fw-6': !isSubmitting,
                            'btn btn-danger w-100 text-white fw-6': isSubmitting,
                          }"
                          :disabled="!namaDonatur || !nomorWhatsApp || !zakatLangsung"
                          @click="donateFromManualInput(productid)"
                        >
                          <span v-if="!isSubmitting">Bayar Zakat</span>
                          <span v-else
                            ><b-spinner
                              small
                              variant="white"
                              label="Spinning"
                            ></b-spinner>
                            Mohon Tunggu...</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

import { VMoney } from "v-money";
export default {
  components: {},
  props: {
    idpro: {
      type: String,
    },
    totalterkumpul: {
      type: String,
    },
  },
  data() {
    return {
      productid: "642e92efb79421734881b53e1e1b18b6",
      showQuestion: 1,
      switchPlan: true,
      selectedTab: "hukum",
      namaDonatur: "",
      nomorWhatsApp: "",
      shopAPI: process.env.VUE_APP_SHOPURL,
      selectedValue: "",
      money: {
        decimal: "",
        thousands: ".",
        prefix: "Rp. ",
        precision: "",
        masked: false,
      },
      tabungan: 0,
      logam: 0,
      surat: 0,
      properti: 0,
      kendaraan: 0,
      koleksi: 0,
      stokdagang: 0,
      hartalain: 0,
      piutanglancar: 0,
      hutangtempo: 0,
      hargaemas: 0,
      totalIncome: 0,
      showAside: false,
      isSubmitting: false,
      zakatLangsung: "",
    };
  },
  computed: {
    jumlahDonasi: function () {
      return this.donasi;
    },
    isB1uttonDisabled() {
      return (
        !this.donasi || this.donasi < 10000 || !this.namaDonatur || !this.nomorWhatsApp
      );
    },
    totalharta() {
      return (
        parseFloat(this.tabungan) +
        parseFloat(this.logam) +
        parseFloat(this.surat) +
        parseFloat(this.properti) +
        parseFloat(this.kendaraan) +
        parseFloat(this.koleksi) +
        parseFloat(this.stokdagang) +
        parseFloat(this.hartalain) +
        parseFloat(this.piutanglancar)
      );
    },
    hartazakat() {
      return this.totalharta - parseFloat(this.hutangtempo);
    },
    isZakatRequired() {
      return this.hartazakat > this.nisabAmount;
    },
    nisabAmount() {
      return this.hargaemas * 85;
    },
    zakatAmount() {
      if (this.hartazakat < this.nisabAmount || this.hartazakat === this.nisabAmount) {
        return 0;
      } else {
        return this.hartazakat * 0.025;
      }
    },
  },
  methods: {
    OpenQuestion(value) {
      this.showQuestion = value;
    },
    change_plan() {
      this.switchPlan = !this.switchPlan;
    },
    selectFeature(name) {
      this.selectedTab = name;
    },

    formatNumber(value) {
      return value.toFixed(0);
    },
    resetForm() {
      this.namaDonatur = "";
      this.nomorWhatsApp = "";
      this.zakatAmount = 0; // Untuk tab perhitungan
    },
    resetForm2() {
      this.namaDonatur = "";
      this.nomorWhatsApp = "";
      this.zakatLangsung = ""; // Untuk tab bayar langsung
    },
    async donate(productid) {
      this.isSubmitting = true;
      setTimeout(() => {
        this.isSubmitting = false;
      }, 2000);
      const dataToSend = {
        productid: productid,
        nama: this.namaDonatur,
        nohp: this.nomorWhatsApp,
        price: this.zakatAmount.toFixed(0),
      };

      try {
        this.isLoading = true;
        const response = await axios.post(
          process.env.VUE_APP_SHOPURL + "/api/transaction/request",
          dataToSend
        );
        if (response.status === 200) {
          var win = window.open(
            response.data.data.paymenturl,
            "PEMBAYARAN DQ PEDULI",
            "width=350, height=700"
          );
          var timer = setInterval(function () {
            if (win.closed) {
              clearInterval(timer);
              location.reload();
            }
          }, 1000);
        }
      } catch (error) {
        console.error(error);
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan saat mengirim data ke server.",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async donateFromManualInput(productid) {
      if (!this.zakatLangsung || this.zakatLangsung <= 0) {
        Swal.fire("Error", "Masukkan jumlah zakat yang valid", "error");
        return;
      }

      this.isSubmitting = true;
      setTimeout(() => {
        this.isSubmitting = false;
      }, 2000);

      const dataToSend = {
        productid: productid,
        nama: this.namaDonatur,
        nohp: this.nomorWhatsApp,
        price: this.zakatLangsung.replace(/\D/g, ""), // Hapus format uang sebelum dikirim
      };

      try {
        this.isLoading = true;
        const response = await axios.post(
          process.env.VUE_APP_SHOPURL + "/api/transaction/request",
          dataToSend
        );
        if (response.status === 200) {
          var win = window.open(
            response.data.data.paymenturl,
            "PEMBAYARAN DQ PEDULI",
            "width=350, height=700"
          );
          var timer = setInterval(function () {
            if (win.closed) {
              clearInterval(timer);
              location.reload();
            }
          }, 1000);
        }
      } catch (error) {
        console.error(error);
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Terjadi kesalahan saat mengirim data ke server.",
        });
      } finally {
        this.isLoading = false;
      }
    },

    closeMenu() {
      const navbarCollapse = document.querySelector(".navbar-collapse");
      navbarCollapse.classList.remove("show");
    },

    directives: {
      money: VMoney,
    },
  },
  created() {
    this.recalculateZakat();
    this.customerNama = this.$route.query["customer[nama]"] || "";
    this.customerNohp = this.$route.query["customer[nohp]"] || "";
  },
};
</script>

<style scoped>
.zakat {
  background-color: #ddd;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #646464;
  cursor: not-allowed;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 21px;
  padding: 11.5px 0;
  text-align: center;
  width: 100%;
}

button:disabled {
  background-color: #b12323;
  border-radius: 8px;
  color: #646464;
  cursor: not-allowed;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  padding: 10.5px 0;
  text-align: center;
  width: 100%;
}
</style>
